import React, { useEffect, useState } from 'react'
import { useIntl, navigate } from 'gatsby-plugin-intl'
import { map } from 'lodash'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import './style.css'
import MetaTags from '../Header/MetaTags'
import EventoDetails from './EventoDetails/EventoDetails'
import ImageBlock from '../ImageBlock/ImageBlock'
import CardEvento from './CardEvento/CardEvento'
import Button from '../Forms/Button/Button'
import arrR from '../../assets/icons/arrR.svg'
import SliderBeato from '../IlBeato/SliderBeato/SliderBeato'
import BlockLayout from '../../layaouts/BlockLayout/BlockLayout'
import Carusel from '../Forms/Carusel/Carusel'
import albero from '../../assets/icons/albero_white.svg'
import FileIcon from '../../assets/icons/file.svg'
import downloadIcon from '../../assets/icons/download.svg'

export default function PaginaEvento({ data }) {
  const intl = useIntl()
  const breakpoints = useBreakpoint()
  const {
    eventDetails,
    foundationHero,
    descriptionParagraph,
    firstImageSlider,
    videoBlock,
    compayPartner,
    socialWall,
    privacyPolicyData,
  } = data[intl.locale].acf
  const { paragraphs, multipleParagraphPosition } = data[
    intl.locale
  ].acf.multipleParagraphs
  const { categories } = data[intl.locale]
  const { yoast_head_json } = data[intl.locale]

  const [collapse, setCollapse] = useState(true)
  const btnLabel = collapse
    ? intl.formatMessage({ id: 'btn_vedi_di_piu' })
    : intl.formatMessage({ id: 'btn_vedi_di_meno' })

  useEffect(() => {
    const mylist = document.getElementById('evento')
    const divs = mylist.children
    const listitems = []
    for (let i = 0; i < divs.length; i++) {
      listitems.push(divs.item(i))
    }

    listitems.sort((a, b) => {
      const compA = a.getAttribute('id')
      const compB = b.getAttribute('id')
      return compA < compB ? -1 : compA > compB ? 1 : 0
    })
    for (let i = 0; i < listitems.length; i++) {
      mylist.appendChild(listitems[i])
    }
  }, [])

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="evento" className="evento_main_container">
        {foundationHero.imageBackgroundFoundationHero && foundationHero.title && (
          <div
            id={foundationHero.positionFoundationHero}
            style={{ width: '100%' }}
          >
            <ImageBlock
              type="3"
              img={foundationHero.imageBackgroundFoundationHero}
              style={{ height: '446px' }}
            >
              <div
                style={{
                  marginLeft: !breakpoints.md ? 130 : 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div className="aziende_title3">{foundationHero.title}</div>
                <h2 className="aziende_subtitle3">
                  {foundationHero.subtitle && foundationHero.subtitle}
                </h2>
              </div>
            </ImageBlock>
          </div>
        )}

        {descriptionParagraph.paragraphTitle &&
          descriptionParagraph.contentVisibile && (
            <div
              style={{ width: !breakpoints.md ? 800 : 330 }}
              id={descriptionParagraph.positionParagraph}
            >
              <div
                className="collapsableTextBox"
                style={{ margin: '0 0 50px 0' }}
              >
                <div className="titleBox">
                  {descriptionParagraph.paragraphTitle}
                </div>
                {descriptionParagraph.contentVisibile && (
                  <div className="collapsable">
                    <div className="collapsableText">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: collapse
                            ? descriptionParagraph.contentVisibile
                            : descriptionParagraph.contentVisibile.concat(
                                descriptionParagraph.contentHidden
                              ),
                        }}
                      />
                    </div>

                    {descriptionParagraph.contentHidden && (
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Button
                          handleClick={() => {
                            setCollapse(!collapse)
                          }}
                          id="collapseBtn"
                          label={btnLabel}
                          style={{
                            borderRadius: 0,
                            textTransform: 'uppercase',
                            color: 'black',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            fontSize: '14px',
                            lineHeight: '21px',
                          }}
                        >
                          {collapse ? (
                            <img
                              alt="accordionIMG"
                              src={arrR}
                              style={{
                                marginLeft: 6,
                                width: '16px',
                                height: '16px',
                              }}
                            />
                          ) : (
                            <img
                              alt="accordionIMG"
                              src={arrR}
                              style={{
                                marginLeft: 6,
                                width: '16px',
                                height: '16px',
                                transform: 'rotate(-180deg)',
                              }}
                            />
                          )}
                        </Button>
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

        {eventDetails && (
          <div className="evento_body" id={eventDetails.eventDetailsPosition}>
            <EventoDetails data={eventDetails} categories={categories} />
          </div>
        )}

        {/* to change with PadletPosition from BE */}
        {socialWall.socialWallContent && (
          <BlockLayout
            id={socialWall.socialWallPosition}
            icon={socialWall.socialWallIcon || null}
            title={socialWall.socialWallTitle || 'title partner'}
            sub={socialWall.socialWallSubtitle || ''}
            style={{ width: '90%', marginBottom: 40 }}
          >
            <div
              className="evento_body--padlet"
              dangerouslySetInnerHTML={{
                __html: socialWall.socialWallContent,
              }}
            />
            {/* <div */}
            {/*  className="padlet-embed" */}
            {/*  id={11} */}
            {/*  style={{ */}
            {/*    marginBottom: 40, borderRadius: 2, boxSizing: 'border-box', overflow: 'hidden', position: 'relative', width: '100%', backgroundColor: '#F4F4F4', */}
            {/*  }} */}
            {/* > */}
            {/*  <p style={{ padding: 0, margin: 0 }}> */}
            {/*    <iframe */}
            {/*      src="https://padlet.com/embed/s7mfoyuv15anfbbc" */}
            {/*      frameBorder="0" */}
            {/*      allow="camera;microphone;geolocation" */}
            {/*      style={{ */}
            {/*        width: '100%', height: 608, display: 'block', padding: 0, margin: 0, */}
            {/*      }} */}
            {/*    /> */}
            {/*  </p> */}
            {/*  <div style={{ padding: 8, textAlign: 'right', margin: 0 }}> */}
            {/*    <a */}
            {/*      href="https://padlet.com?ref=embed" */}
            {/*      style={{ */}
            {/*        padding: 0, margin: 0, border: 'none', display: 'block', lineHeight: 1, height: 16, */}
            {/*      }} */}
            {/*      target="_blank" */}
            {/*      rel="noreferrer" */}
            {/*    > */}
            {/*      <img */}
            {/*        src="https://padlet.net/embeds/made_with_padlet.png" */}
            {/*        width="86" */}
            {/*        height="16" */}
            {/*        style={{ */}
            {/*          padding: 0, margin: 0, background: 'none', border: 'none', display: 'inline', boxShadow: 'none', */}
            {/*        }} */}
            {/*        alt="Made with Padlet" */}
            {/*      /> */}
            {/*    </a> */}
            {/*  </div> */}
            {/* </div> */}
          </BlockLayout>
        )}

        {firstImageSlider.firstImageSlider.length > 0 && (
          <SliderBeato
            data={firstImageSlider.firstImageSlider}
            id={firstImageSlider.positionFirstImageSlider}
            style={{ margin: '40px 0', width: !breakpoints.md ? 800 : 330 }}
          />
        )}

        {paragraphs?.length > 0 && (
          <div
            style={{ width: !breakpoints.md ? 800 : 330 }}
            id={multipleParagraphPosition}
          >
            {paragraphs.map(p => (
              <div className="collapsableTextBox" style={{ margin: '20px 0' }}>
                <div
                  className="titleBox"
                  dangerouslySetInnerHTML={{
                    __html: p.title,
                  }}
                  style={{ fontFamily: 'Source Serif Pro' }}
                />
                {p.description && (
                  <div className="collapsable">
                    <div className="collapsableText">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `${p.description}`,
                        }}
                      />
                    </div>
                  </div>
                )}
                {p.ctaUrl && (
                  <a href={p.ctaUrl}>
                    <Button
                      label={p.ctaLabel}
                      handleClick={() => navigate(p.ctaUrl)}
                      style={{
                        width: 160,
                        backgroundColor: '#D75F2B',
                        color: 'white',
                        marginTop: 20,
                      }}
                    />
                  </a>
                )}
              </div>
            ))}
          </div>
        )}

        {videoBlock.videoUrl && (
          <div
            className="singleProjectPage_media"
            id={videoBlock.videoPosition}
            style={{ margin: '40px 0', width: !breakpoints.md ? 800 : 330 }}
            dangerouslySetInnerHTML={{ __html: videoBlock.videoUrl }}
          />
        )}

        {compayPartner.partnerLogo.length > 0 && (
          <div
            id={compayPartner.positionPartner}
            style={{
              width: '100%',
              height: '100%',
              marginBottom: 40,
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#f8f9fa',
                backgroundImage: `url(${albero})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: !breakpoints.md ? '80%' : '250%',
                backgroundPosition: !breakpoints.md ? '250% 10%' : '0% 120%',
                padding: '40px 0',
              }}
            >
              <BlockLayout
                icon={compayPartner.partnerIcon || null}
                title={compayPartner.partnerSectionTitle || 'title partner'}
              >
                <br />
                {compayPartner.partnerLogo.length > 2 ? (
                  <Carusel
                    component="Evento"
                    cards={compayPartner.partnerLogo}
                    width={!breakpoints.md ? 384 : 207}
                    height={!breakpoints.md ? 341 : 183}
                    initialSlide={0}
                  />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignitems: 'center',
                      justifyContent: 'center',
                      marginBottom: 50,
                    }}
                  >
                    {compayPartner.partnerLogo.map(logo => (
                      <div
                        style={{
                          width: !breakpoints.md ? 380 : 246,
                          height: !breakpoints.md ? 380 : 185,
                          margin: '0 10px',
                        }}
                      >
                        <CardEvento key={logo} card={logo} />
                      </div>
                    ))}
                  </div>
                )}
              </BlockLayout>
            </div>
          </div>
        )}

        {privacyPolicyData && (
          <div
            className="evento_body"
            id={privacyPolicyData.privacyPolicyPosition}
            style={{ width: !breakpoints.md ? 800 : 330 }}
          >
            <div className="eventiPDFs-container">
              <div className="eventiPDFs-title">
                {privacyPolicyData.filesSectionTitle}
              </div>
              <div className="privacyPDFs-list">
                {privacyPolicyData.privacyPolicyPdfFiles !== undefined &&
                  privacyPolicyData.privacyPolicyPdfFiles.length > 0 &&
                  privacyPolicyData.privacyPolicyPdfFiles.map(file => (
                    <div className="eventi-file">
                      <div className="eventi-file-side">
                        <div className="eventi-file-icon">
                          <img
                            className="eventi-file-icon-img"
                            alt="file-icon"
                            src={FileIcon}
                          />
                        </div>
                        <div className="eventi-file-name">
                          {file.privacyPolicyFileTitle !== ''
                            ? file.privacyPolicyFileTitle
                            : file.privacyPolicyFile.title}
                        </div>
                      </div>
                      <img
                        className="eventi-download-icon"
                        src={downloadIcon}
                        onClick={() =>
                          window.open(file.privacyPolicyFile.url, '_blank')
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
