import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './style.css';
import {
  atcb_action as atcbAction,
  atcb_init as atcbInit,
} from 'add-to-calendar-button';
import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import exit from '../../../assets/icons/exit.svg';

export default function PaginaEvento({ data, categories }) {
  const intl = useIntl();
  const breakpoints = useBreakpoint();
  const eventDetails = data?.eventsInformation ? data?.eventsInformation : [];
  const eventCalendar = data?.eventCalendar[0] ? data?.eventCalendar[0] : {};
  const EventLinks = data?.eventsLinks[0] ? data?.eventsLinks[0] : {};
  const [showShareModal, setShowShareModal] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => atcbInit());

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowShareModal(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const ShareModal = useMemo(() => {
    if (showShareModal) {
      return (
        <div className="share-modal">
          <div className="share-cont" id="share-modal" ref={wrapperRef}>
            <div className="close-share">
              <div className="share-header">
                {intl.formatMessage({ id: 'share' })}
                <img
                  src={exit}
                  className="exit-share-icon"
                  alt="exit-share"
                  onClick={() => setShowShareModal(false)}
                />
              </div>
              <div className="share-socials">
                {/* eslint-disable-next-line no-undef */}
                <EmailShareButton
                  className="share-social"
                  url={window.location.href}
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
                {/* eslint-disable-next-line no-undef */}
                <FacebookShareButton
                  className="share-social"
                  url={window.location.href}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                {/* eslint-disable-next-line no-undef */}
                <TelegramShareButton
                  className="share-social"
                  url={window.location.href}
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                {/* eslint-disable-next-line no-undef */}
                <TwitterShareButton
                  className="share-social"
                  url={window.location.href}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                {/* eslint-disable-next-line no-undef,max-len */}
                <WhatsappShareButton url={window.location.href}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return null
  }, [intl, showShareModal])

  return (
    <div style={{ width: !breakpoints.md ? 800 : 330 }}>
      {categories.length > 0 && (
        <div
          className="singleProjectPage_projectCategory"
          style={{ margin: '40px 0 10px' }}
        >
          {categories.length > 0 && (
            <div className="singleProjectPage_projectCategoriesIcons">
              {categories.map(cat => {
                if (!cat.acf['Category Icon']?.categoryIcon) return null
                return (
                  <div
                    style={{
                      backgroundColor: cat.acf['Category Icon'].categoryColor,
                      width: !breakpoints.md ? 70 : 38,
                      height: !breakpoints.md ? 70 : 38,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={cat.acf['Category Icon'].categoryIcon}
                      alt="catIcon"
                      style={{ width: !breakpoints.md ? 40 : 20 }}
                    />
                  </div>
                )
              })}
            </div>
          )}
          {categories.length > 0 && (
            <div className="singleProjectPage_projectCategories">
              {categories.map((cat, index) => {
                if (!cat.name) return null
                return (
                  <div style={{ marginRight: 5 }}>
                    {cat.name}
                    {index !== categories.length - 1 && ','}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}
      <div className="event-details-container">
        <div className="event-details">
          {eventDetails.map(ev => {
            if (!ev.eventIcon || !ev.eventSubtitle || !ev.eventTitle)
              return null
            return (
              <div className="event-detail">
                <div className="event-icon2">
                  <img
                    src={ev.eventIcon}
                    alt="catIcon"
                    style={{
                      width: 'auto',
                      height: 18,
                      marginTop: 6,
                    }}
                  />
                </div>
                <div className="event-desc">
                  <div
                    className="event-title"
                    dangerouslySetInnerHTML={{
                      __html: ev.eventTitle,
                    }}
                  />
                  <div
                    className="event-subtitle"
                    dangerouslySetInnerHTML={{
                      __html: ev.eventSubtitle,
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className="event-btns">
          {(EventLinks?.linkLabel && EventLinks?.linkIcon) && (
              <div 
                className="event-detail pointer"
                style={{
                  minHeight: 38,
                  display: 'flex',
                  alignItems: !breakpoints.md ? 'start' : 'center',
                }}
                onClick={() => setShowShareModal(true)}
              >
              <div
                className="event-icon"
                style={{
                  backgroundColor: 'white',
                  padding: 8,
                  borderRadius: '50%',
                }}
              >
                <img
                  src={EventLinks.linkIcon}
                  alt="catIcon"
                  style={{
                    width: 16,
                    height: 'auto',
                  }}
                />
              </div>
              <div className="event-desc">
                <div className="black-txt">{EventLinks.linkLabel}</div>
              </div>
            </div>
          )}
          {(eventCalendar.event && eventCalendar.btnLabel && eventCalendar.icon) && (
            <div
              className="event-detail pointer"
              style={{
                minHeight: 38,
                display: 'flex',
                alignItems: !breakpoints.md ? 'start' : 'center',
              }}
              onClick={() =>
                atcbAction({
                  name: eventCalendar?.event?.title,
                  description: eventCalendar?.event?.description,
                  location: eventCalendar?.event?.location,
                  startDate: eventCalendar?.event?.startTime.replace(' ', 'T'),
                  endDate: eventCalendar?.event?.endTime.replace(' ', 'T'),
                  options: ['Apple', 'Google', 'MicrosoftTeams', 'Outlook.com'],
                  trigger: 'click',
                  iCalFileName: 'Reminder-Event',
                })
              }
            >
              <div
                className="event-icon"
                style={{
                  backgroundColor: 'white',
                  padding: 8,
                  borderRadius: '50%',
                }}
              >
                <img
                  src={eventCalendar?.icon}
                  alt="catIcon"
                  style={{
                    width: 16,
                    height: 'auto',
                  }}
                />
              </div>
              <div className="event-desc">
                <div className="black-txt">{eventCalendar.btnLabel}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {ShareModal}
    </div>
  )
}
