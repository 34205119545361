import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import PaginaEvento from '../components/PaginaEvento/PaginaEvento'

import MainLayout from '../layaouts/MainLayout/MainLayout'

function PaginaEventoPage({ pageContext }) {
  const { data } = pageContext

  return (
    <MainLayout>
      <PaginaEvento data={data} />
    </MainLayout>
  )
}

export default injectIntl(PaginaEventoPage)
